import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'

import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Facial Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Earlobe Repair',
  otherOptions: [
    // {
    //   text: 'Facelift',
    //   to: '/procedures/facelift',
    // },
    {
      text: 'Nose Surgery',
      to: '/procedures/nose-surgery',
    },
    {
      text: 'Ear Correction',
      to: '/procedures/ear-correction',
    },
    {
      text: 'Earlobe Repair',
      to: '/procedures/ear-reconstruction',
    },
    {
      text: 'Eyelid Surgery',
      to: '/procedures/eyelid-surgery',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Dr. Graham Sellars can reconstruct the shape of your damaged ears due to split earlobes and stretched earlobes."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
    </Hero>

    <Content>
      <h2>Earlobe Repair</h2>
      <h4>Split Earlobes or Stretched Ear Piercing Defects</h4>
      <p>
        Some people experience problems with their earlobes related to ear
        piercing. This can be due to conventional ear piercing that has become
        stretched, or from deliberately stretching the earlobe with large ear
        studs or ear piercing.
      </p>

      <h4>This can create one of two problems:</h4>

      <p>
        Heavy earrings can stretch a normal, small ear piercing to a defect that
        is too large to hold a small ear stud, leading to small earrings falling
        out.
      </p>

      <p>
        Larger ear studs can leave a larger defect in the stretched earlobe
        which some people may regret and wish to have repaired. This can be a
        problem for people wishing to join the Defence Force for example.
      </p>

      <h4>What Can Be Done With Split Earlobes</h4>

      <p>
        The split earlobe defects and stretched ear piercing defects can be
        repaired quite simply under local anaesthetic.
      </p>

      <p>
        There are two main options which can be employed depending on whether
        one desires to maintain an ear piercing or not.
      </p>

      <p>
        A small flap of skin can be rotated to maintain the piercing whilst
        repairing the stretched earlobe defect around it. This gives a linear
        scar which typically extends down to the ear margin and heals very well.
      </p>

      <p>
        Alternatively the previous piercing can be excised. This tends to
        elongate the earlobe which can make it look a little pointy and it may
        be better to remove a wedge of the earlobe so that it heals with a more
        normal looking shape. Each situation is slightly different and the
        technique requires tailoring to the individual.
      </p>

      <p>
        The procedure is done under local anaesthetic and typically takes one
        hour. It can be done in a Day Surgery or as an office procedure. Deep
        dissolving sutures are used as well as sutures that are removed the
        following week. Typically one should not wear piercing for six weeks.
      </p>

      <h4>Possible risks of any surgery include:</h4>
      <ul>
        <li>Wound infection</li>
        <li>Pain and discomfort</li>
        <li>Nausea</li>
        <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
        <li>Heavy bleeding from the incisions</li>
        <li>Keloid or hypertrophic scars</li>
        <li>Slow healing</li>
        <li>Separation of the wound edges</li>
        <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
        <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
      </ul>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query EarReconstructionQuery {
    allFile(filter: { relativeDirectory: { eq: "breast-augmentation" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "sellars-earlobe.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "flower.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
