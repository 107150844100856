import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Interplast - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Read up on our involvement with the Interplast charity foundation. We're always looking to do our part."
      />
    </Helmet>
    <Hero old={true} bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>Interplast</h1>
      <p>
        Dr Sellars went on his first program in 2006 to The Solomon Islands and
        has since been on 5 more to Tonga in 2012, 2013, 2017, 2018 and 2023.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
      <p style={{ fontSize: '12px', marginTop: 20 }}>* Photo courtesy of Woodrow Wilson Clinical Imaging</p>
    </Hero>
    <Content style={{ marginTop: 50 }}>
      <h2>Interplast</h2>

      <Img
        fluid={data.overseas.childImageSharp.fluid}
        style={{ borderRadius: 8, marginBottom: 5 }}
      />
      <p style={{ fontSize: 12, textAlign: 'center' }}>
        Photo courtesy of Woodrow Wilson Clinical Imaging
      </p>

      <p>
        Interplast sends teams of volunteer plastic and reconstructive surgeons,
        anaesthetists, nurses and allied health professionals to provide
        life-changing surgery and medical training in 17 countries across the
        Asia Pacific region.
      </p>

      <p>
        We do this in partnership with local organisations, including hospitals,
        universities and local NGOs. Our mission is to ‘repair bodies and
        rebuild lives’ and we do this through providing surgical services to
        those who could not otherwise afford or access these, and by building
        the capacity of local medical systems through our training and mentoring
        programs.
      </p>

      <span>
        If you would like to support Interplast’s work please visit:{' '}
        <a href="https://www.interplast.org.au/donate/">
          https://www.interplast.org.au/donate/
        </a>
      </span>


      <Img
        fluid={data.trip.childImageSharp.fluid}
        style={{ borderRadius: 8, marginBottom: 5 }}
      />
      <p style={{ fontSize: 12, textAlign: 'center' }}>
        Photo courtesy of Woodrow Wilson Clinical Imaging
      </p>

      <Flex style={{ marginTop: 25 }}>
        <a href="https://www.plasticsurgeryhub.com.au/tonga-plastic-surgery-dr-graham-sellars/">
          <Button inverted={true}>Read the full article</Button>
        </a>
      </Flex>

      <Flex style={{ marginTop: 25 }}>
        <a href="https://fb.watch/kJxh8eZ2DD/?mibextid=qC1gEa">
          <Button inverted={true}>Watch Interview - Mālō ‘aupito (Thank You)</Button>
        </a>
      </Flex>
    </Content>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>Do you have questions?</h1>
      <p>
        Call 02 9480 8577 or please complete the enquiry form to contact us
        today and find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query InterplastQuery {
    overseas: file(relativePath: { eq: "overseas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trip: file(relativePath: { eq: "trip.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "tonga.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
