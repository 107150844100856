import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Body Surgery Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Mole Removal',
  otherOptions: [
    // {
    //   text: 'Liposuction',
    //   to: '/procedures/liposuction',
    // },
    {
      text: 'Abdominoplasty (Tummy Tuck)',
      to: '/procedures/tummy-tuck',
    },
    {
      text: 'Skin Cancer Surgery',
      to: '/procedures/skin-cancer-surgery',
    },
    {
      text: 'Scar Revision',
      to: '/procedures/scar-revision',
    },
    {
      text: 'Mole Removal',
      to: '/procedures/mole-removal',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
    </Helmet>

    <Hero bgImage={data.moleRemoval.childImageSharp} height="600px">
    </Hero>

    <Content>
      <h2>Mole Removal</h2>
      <h4>
        Dr Sellars performs a range of surgical excision procedures including
        mole removal surgery.
      </h4>
      <p>
        As a Specialist Plastic Surgeon, Dr Sellars has been trained in surgical
        techniques that minimize scarring and give optimal aesthetic results. Dr
        Sellars takes great care to provide an excellent cosmetic result,
        whether it be a large cosmetic procedure or a simple lesion excision.
        Many procedures can be performed in the consulting rooms under local
        anaesthetic. However more complex procedures that require skin flaps or
        skin grafts will be done under sedation or general anaesthetic in
        hospital. Most children under the age of 12 will not be done with local
        anaesthetic in the rooms.
      </p>
      <p></p>
      <h4>
        For skin cancers and mole removal - The initial appointment will be a
        consultation only, and no extra time for any procedure can be allocated
        at this time, (eg excision of lesion).
      </h4>
      <p>
        Once Dr Sellars has assessed the area of concern, another appointment
        for excision can be made. This will depend on the site and size of
        lesion and age and medical condition of patient.
      </p>
      <p>
        Other factors to consider are - can it be done in the rooms under local
        anaesthetic or is a sedation or general anaesthetic required, which
        would mean a hospital admission.
      </p>
      <h4>
        Please note Dr Sellars does not perform routine skin checks. These are
        done by the patient’s local GP or dermatologist.
      </h4>
      <p>
        Dr Sellars can perform biopsies at time of consultation if required.
      </p>

      <h4>Possible risks of any surgery include:</h4>
      <ul>
        <li>Wound infection</li>
        <li>Pain and discomfort</li>
        <li>Nausea</li>
        <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
        <li>Heavy bleeding from the incisions</li>
        <li>Keloid or hypertrophic scars</li>
        <li>Slow healing</li>
        <li>Separation of the wound edges</li>
        <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
        <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
      </ul>

      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query MoleRemovalQuery {
    moleRemoval: file(relativePath: { eq: "sellars-mole.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "water-body-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
