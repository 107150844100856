import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
  Video,
} from '../../components/procedures'

const content = {
  backgroundTitle: 'Other Non-Surgical Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Treatment for Wrinkles and Volume Loss',
  otherOptions: [
    {
      text: 'Treatment for Wrinkles and Volume Loss',
      to: '/non-surgical/dermal-fillers',
    },
    // {
    //   text: 'Cool Sculpting',
    //   to: '/non-surgical/coolsculpting',
    // },
    {
      text: 'Skin Care',
      to: '/non-surgical/skin-treatments',
    },
    {
      text: 'Excess Underarm Sweating',
      to: '/non-surgical/excess-underarm-sweating',
    },
    // {
    //   text: 'Anti-Wrinkle Injections',
    //   to: '/non-surgical/muscle-relaxants',
    // },
    // {
    //   text: 'IPL & Laser Treatments',
    //   to: '/non-surgical/ipl-and-laser-treatments'
    // }
  ],
}

export default ({ data }: { data: any }) => {
  return (
    <MainLayout header="dark">
      <Helmet>
        <title>{content.title} - Plastic Surgery Sydney</title>
        <meta
          name="description"
          content="With treatment for Wrinkles and Volume Loss, you can smoothen wrinkles, and achieve a rejuvenated and youthful glow, which is something every woman aims for."
        />
      </Helmet>

      <div style={{ marginTop: 150 }}></div>

      {/* <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
        <h1>{content.title}</h1>
        <p>{content.subtitle}</p>
        <Flex>
          <Button color="#fff" small={true} outline={true}>
            Learn more
          </Button>
        </Flex>
      </Hero> */}

      <Content>
        <h2>Treatment for Wrinkles and Volume Loss</h2>

        <p>
          In March 2024 the TGA brought in regulations to ban the use of advertising or using product names for Schedule 4 drugs commonly used in injectable treatments. We are only able to give general information regarding our non-surgical services.
          We offer consultations for treatments to reduce wrinkles and address volume loss, and can discuss in more detail the treatment plan best suited to you.

          Unfortunately as we age lines on the face appear. And living in Australia, sun-damage is just a part of life.

          The cause of wrinkles is varied, however, when you laugh, frown, concentrate or squint your facial muscles contract, causing wrinkles to form. These wrinkles can leave you looking tired, sad or angry. In particular, the frown lines are a concern for most people. We also lose volume in the fat pads in our face as we age which can leave areas of atrophy and skin laxity.

        </p>
        <h4>Treatments are designed to be used for the below areas on the face:</h4>

        <ul>
          <li>Frownlines</li>
          <li>Horizontal forehead creases</li>
          <li>Crowsfeet creases</li>
          <li>Nasolabial lines (smile lines)</li>
          <li>Perioral lines (smokers lines above upper lip)</li>
          <li>Oral commissures and corners of mouth (marionette lines)</li>
          <li>Lip augmentation / enhancement (enlargement).</li>
          <li>Cheek enhancement and tear trough hollows</li>
        </ul>

        <h4>Possible risks include:</h4>
        <ul>
          <li>Eyelid ptosis</li>
          <li>Asymmetry</li>
          <li>Bleeding</li>
          <li>Bruising</li>
          <li>Damage to the skin that results in a wound and possible scarring</li>
          <li>Infection at the site</li>
          <li>Lumps</li>
          <li>Skin necrosis (ulceration or loss of skin from disruption of blood flow)</li>
          <li>Skin rash with itching</li>
          <li>Skin redness</li>
          <li>Swelling</li>
          <li>Under- or over-correction of wrinkles</li>
        </ul>

        <Link to="/contact/">
          <Button inverted={true}>Contact us today!</Button>
        </Link>
      </Content>




      <AboutContact data={data} />
    </MainLayout >
  )
}

export const query = graphql`
        query DermalFillerQuery {
          allFile(filter: {relativeDirectory: {eq: "dermal-fillers" } }) {
          edges {
          node {
          childImageSharp {
          resolutions(width: 500, toFormat: JPG) {
          ...GatsbyImageSharpResolutions
        }
          }
        }
      }
    }
        womanWaterfall: file(relativePath: {eq: "background-generic.jpg" }) {
          childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
        otherBreast: file(relativePath: {eq: "non-surgical-woman.jpg" }) {
          childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
        asaps: file(relativePath: {eq: "ASAPS.png" }) {
          childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
        asps: file(relativePath: {eq: "ASPS.png" }) {
          childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
        drSellars: file(relativePath: {eq: "dr-graham-sellars.jpg" }) {
          childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
        fracs: file(relativePath: {eq: "FRACS.png" }) {
          childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
        `
