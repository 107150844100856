import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Non-Surgical Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Excess Underarm Sweating',
  otherOptions: [
    {
      text: 'Treatments for Wrinkles and Volume Loss',
      to: '/non-surgical/dermal-fillers',
    },
    // {
    //   text: 'Cool Sculpting',
    //   to: '/non-surgical/coolsculpting',
    // },
    {
      text: 'Skin Care',
      to: '/non-surgical/skin-treatments',
    },
    {
      text: 'Excess Underarm Sweating',
      to: '/non-surgical/excess-underarm-sweating',
    },
    // {
    //   text: 'IPL & Laser Treatments',
    //   to: '/non-surgical/ipl-and-laser-treatments'
    // }
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Around the world, excessive sweating is a condition that is experienced by millions of people. Consult with Dr Sellars to help you resolve this condition."
      />
    </Helmet>
    <div style={{ marginTop: 150 }}></div>
    {/* 
    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero> */}

    <Content>
      <h2>Excess Underarm Sweating (Hyperhidrosis)</h2>
      <p>
        Around the world, excessive sweating is a condition that is experienced
        by millions of people.
      </p>

      <p>
        For sufferers of hyperhidrosis topical agents such as antiperspirants are usually
        not adequate to reduce the excessive sweating. There are surgical and non-surgical treatments available for hyperhidrosis.
        The TGA does not allow any advertising of schedule 4 drugs used in these treatments so more detail will be given at time of consultation.
      </p>

      <h4>No more embarrassing wet, smelly patches!</h4>
      <p>
        After a treatment, sufferers find that they no longer drip and have
        discoloured clothing.
        It gives people a sense of freedom in social situations, where they would otherwise have
        been very self-conscious about their sweating. Most people experience a
        dramatic reduction in sweating within a couple of days of having the
        treatment.
      </p>

      <p>
        The treatment takes just 15 minutes, and lasts on average around 9
        months. It is not considered a cosmetic treatment, and there is a
        medicare rebate available for the cost of the treatment.
      </p>

      <p>
        Many people consider this treatment for a special occasion such as an
        impending wedding, or a job interview, when the sweat glands will be
        more active than usual.
      </p>

      <h4>Possible risks include:</h4>
      <ul>
        <li>Swelling</li>
        <li>Redness and irritation</li>
        <li>Pain and tenderness in injection areas</li>
        <li>Headache and nausea</li>
        <li>Bruising</li>
        <li>Compensatory sweating</li>
      </ul>

      <h4>
        For a free consultation and advice regarding this treatment, please
        contact our office on 02 9480 8577
      </h4>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query UnderarmQuery {
    womanWaterfall: file(relativePath: { eq: "sweating.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "non-surgical-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
