import * as React from 'react'
import { Flex, Box } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'
import GalleryImage from '../../components/GalleryImage'
import GalleryContainer from '../../components/GalleryContainer'

const content = {
  backgroundTitle: 'Other Non-Surgical Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'IPL & Laser Treatment',
  otherOptions: [
    {
      text: 'Treatments for Wrinkles and Volume Loss',
      to: '/non-surgical/dermal-fillers',
    },
    // {
    //   text: 'Cool Sculpting',
    //   to: '/non-surgical/coolsculpting',
    // },
    {
      text: 'Skin Care',
      to: '/non-surgical/skin-treatments',
    },
    {
      text: 'Excess Underarm Sweating',
      to: '/non-surgical/excess-underarm-sweating',
    },
    // {
    //   text: 'IPL & Laser Treatments',
    //   to: '/non-surgical/ipl-and-laser-treatments'
    // }
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="IPL And Laser Treatments IPL and Laser treatments are now available in our practice with the platform. This system enables enhanced ability to close larger, deeper facial vessels, including rosacea, telangiectasia, angiomas, hemangiomas, reticular veins and port wine stains.It also obtains superior results on pigmented lesions including sun damage, sun spots and liver spots and with minimal patient downtime."
      />
    </Helmet>

    <div style={{ marginTop: 150 }}></div>

    {/* <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero> */}

    <Content>
      <h2>Laser Treatments (IPL)</h2>
      <h4>
        IPL and Laser treatments are now available in our practice with the ICON
        platform.
      </h4>
      <p>
        This system enables enhanced ability to close larger, deeper facial
        vessels, including rosacea, telangiectasia, angiomas, hemangiomas,
        reticular veins and port wine stains.It also obtains superior results on
        pigmented lesions including sun damage, sun spots and liver spots and
        with minimal patient downtime.
      </p>
      <p>
        We can also treat benign skin lesions such as seborrhoeic keratoses and
        benign moles. The cooler months are the ideal time to consider IPL
        treatments particularly to the face and décolletage as sun exposure is
        minimized – therefore maximizing the greatest results.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <Box my={5}>
      <h2 style={{ fontWeight: 400, textAlign: 'center' }}>
        Before &amp; After
      </h2>
      <GalleryContainer style={{ marginTop: 30 }}>
        {data.iplGallery.edges
          .sort((x, y) => {
            if (
              x.node.childImageSharp === null ||
              y.node.childImageSharp === null
            ) {
              return 0
            }
            return x.node.childImageSharp.fluid.src.localeCompare(
              y.node.childImageSharp.fluid.src
            )
          })
          .map((image, i) => {
            if (image.node.childImageSharp === null) {
              return null
            }
            return (
              <GalleryImage noModal={true}>
                <Img fluid={image.node.childImageSharp.fluid} />
              </GalleryImage>
            )
          })}
      </GalleryContainer>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </Box>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query IPLQuery {
    iplGallery: allFile(filter: { relativeDirectory: { eq: "ipl" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 340, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "skin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "non-surgical-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
