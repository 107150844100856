import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Facial Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Eyelid Surgery',
  otherOptions: [
    // {
    //   text: 'Facelift',
    //   to: '/procedures/facelift',
    // },
    {
      text: 'Nose Surgery',
      to: '/procedures/nose-surgery',
    },
    {
      text: 'Ear Correction',
      to: '/procedures/ear-correction',
    },
    {
      text: 'Earlobe Repair',
      to: '/procedures/ear-reconstruction',
    },
    {
      text: 'Eyelid Surgery',
      to: '/procedures/eyelid-surgery',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>Eyelid Surgery in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="Your eyelids are the first area to shows signs of ageing making your eyes look tired and sleepy. Consult with Dr. Sellars for this concern."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
    </Hero>

    <Content>
      <h2>Eyelid Surgery - Blepharoplasty</h2>
      <p>
        The eyelids are one of the first areas of the face to show changes with
        ageing. Most commonly this occurs with drooping upper eyelids or
        puffiness of the lower eyelids, giving a tired appearance.
      </p>
      <p>
        Blepharoplasty is an operation designed to remove excess skin folds and
        bulging fat bags. It rejuvenates the upper part of the face, making you
        look fresher and more relaxed, and feeling more self-confident.
        Blepharoplasty is often combined with a browlift to lift sagging
        eyebrowsgi or necklift to improve the lower part of the
        face.
      </p>

      <p>
        Blepharoplasty is a commonly performed procedure with a very high degree
        of patient satisfaction and low risk of complications.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Pre operative care</StyledTab>
        <StyledTab>Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>
          There are many medical conditions which may affect the outcome of
          Eyelid Surgery, including thyroid disease, high blood pressure,
          smoking, and eye conditions such as glaucoma, dry eyes, and allergic
          conjunctivitis, and you should discuss these with your doctor prior to
          surgery.
        </h4>

        <p>
          Preoperative consultation on at least two separate occasions is
          essential to ensure a realistic expectation of the surgery. This
          involves your Surgeon showing you before and after photos of other
          patients who have undergone the surgery, giving you reading material
          on the surgery, and ideally talking to a patient who has had this type
          of surgery.
        </p>

        <p>
          Most insurance companies and Medicare do not cover Eyelid Surgery
          unless upper lid skin excess affects or obscures vision. If the upper
          eyelid skin touches or hangs over the lash/eyelid margin, then
          Medicare and health insurance companies contribute to the cost of the
          surgery. Your Surgeon can advise you if this may be the situation for
          you.
        </p>

        <p>
          In some cases Dr. Sellars may require you to have an eye examination
          with an eye Specialist prior to the surgery.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Surgery can be performed under local anaesthetic, twilight sedation or
          general anaesthetic depending on what the surgery involves and the
          patient’s preference.
        </h4>

        <p>
          Upper Eyelid Surgery is usually a day-only procedure, but when both
          upper and lower eyelid surgery is performed it usually involves an
          overnight stay in hospital.
        </p>

        <p>
          Upper eyelid incisions are hidden in natural creases, while lower
          eyelid incisions usually run just under the eyelash line. Both
          incisions may extend into the crows feet (smile lines) at the outer
          edge of the eye.
        </p>

        <p>
          During the surgery, Dr. Sellars will separate the skin from underlying
          fatty tissue and muscle, remove excess fat and trim sagging skin. The
          muscle may be trimmed or tightened with sutures. The incisions are
          then closed with very fine sutures.
        </p>

        <p>
          Surgical scars will be situated in the upper eyelid crease or just
          under the lower lashes, and are therefore inconspicuous.
        </p>

        <p>
          The Surgery usually takes one to three hours if you’re having all four
          eyelids done, or half that time if you are only having upper or lower
          eyelid surgery alone.
        </p>
      </TabPanel>
      <TabPanel>
        <p>
          Following the operation it is best to sleep with several pillows
          keeping your head in an elevated position for the first few days to
          help minimise the bruising and swelling. Using ice packs during this
          time is also very helpful.
        </p>

        <p>
          After surgery, Dr. Sellars will probably lubricate your eyes with
          ointment and may apply a bandage. Your eyelids may feel tight and
          sore. Pain is usually minimal; however, Dr. Sellars will prescribe
          medication for you to eliminate this discomfort, and the swelling and
          bruising usually resolves within 2-4 weeks.
        </p>

        <p>
          Strenuous activity, lifting, stooping and bending should be avoided
          for the first week or two.
        </p>

        <p>
          The bruising and swelling usually lasts between one and three weeks
          and is very variable in extent and duration. In this early stage eyes
          are commonly red and feel dry or itchy. Ointment helps keep the eyes
          moist, especially at night, as the lids may not fully close for a few
          weeks.
        </p>

        <p>
          Sutures are removed by the surgeon after five days. Light sensitivity,
          excess tearing, and blurred vision may be present for a couple of
          weeks (rarely longer).
        </p>

        <p>
          Most patients return to driving and work within one to two weeks. Eye
          makeup can be used from about two weeks and is useful if there is
          persistent bruising.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific Eyelid Surgery risks:</h4>
        <ul>
          <li>Temporary swelling, leading to a tight sensation</li>
          <li>Bruising which persists for 2-3 weeks</li>
          <li>Removal of too much skin</li>
          <li>Asymmetry of the eyelids</li>
          <li>Itchiness or dry-eye symptoms</li>
          <li>Changes in vision which usually resolve quickly. Rarely some may be long lasting</li>
          <li>Rarely blindness may result</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After (Under Construction)</h2>
      <div>
        {data.allFile.edges
          .sort((x, y) => {
            if (
              x.node.childImageSharp === null ||
              y.node.childImageSharp === null
            ) {
              return 0
            }
            return x.node.childImageSharp.resolutions.src.localeCompare(
              y.node.childImageSharp.resolutions.src
            )
          })
          .map(
            (image) =>
              image.node.childImageSharp && (
                <Img resolutions={image.node.childImageSharp.resolutions} />
              )
          )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query EyelidSurgeryQuery {
    allFile(filter: { relativeDirectory: { eq: "gallery/eyelid" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "sellars-eyelid.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "woods-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
