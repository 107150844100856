import * as React from 'react'
import { Flex, Box } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import FAQs from '../../components/FAQs'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
  Video,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Non-Surgical Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Skin Care',
  otherOptions: [
    {
      text: 'Treatments for Wrinkles and Volume Loss',
      to: '/non-surgical/dermal-fillers',
    },
    // {
    //   text: 'Cool Sculpting',
    //   to: '/non-surgical/coolsculpting',
    // },
    {
      text: 'Skin Care',
      to: '/non-surgical/skin-treatments',
    },
    {
      text: 'Excess Underarm Sweating',
      to: '/non-surgical/excess-underarm-sweating',
    },
    // {
    //   text: 'IPL & Laser Treatments',
    //   to: '/non-surgical/ipl-and-laser-treatments'
    // }
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="At our San Clinic suite, we also offer a wide variety of skin care products. Dr Sellars and his trained team are available to advise you of the products best suited to your individual skin condition."
      />
    </Helmet>

    <div style={{ marginTop: 150 }}></div>

    {/* <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero> */}

    <Content>
      <h2>Skin Care</h2>
      <p>
        Without correct skin care, your skin will prematurely age, making your
        skin look tired and dull.
      </p>
      <p>
        At our San Clinic suite, we also offer a wide variety of skin care
        products. Dr Sellars and his trained team are available to advise you of
        the products best suited to your individual skin condition.
      </p>

      <h4>
        Dr Sellars recommends and stocks Jan Marini Skin Research Products
        including the below:
      </h4>

      <ul>
        <li>Excellent Sunscreens</li>
        <li>Cleansers and Moisturisers</li>
        <li>Acne Products</li>
        <li>Vitamin A, C & E</li>
        <li>Glycolic Acids</li>
        <li>Alpha Hydroxy Acids (AHA’s)</li>
        <li>Camouflage make-up</li>
        <li>
          With the correct skin care routine you can counteract the effects of
          aging and sun-damage and rejuvenate the appearance of your face.
        </li>
      </ul>

      <Flex flexWrap="wrap" justifyContent="flex-start" mb={4}>
        <Img resolutions={data.cos1.childImageSharp.resolutions} />
      </Flex>

      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query SkinTreatmentQuery {
    womanWaterfall: file(relativePath: { eq: "girl-flower.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "non-surgical-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cos1: file(relativePath: { eq: "cosmetics-1.jpg" }) {
      childImageSharp {
        resolutions {
          ...GatsbyImageSharpResolutions
        }
      }
    }

    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
