import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Facial Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Nose Surgery',
  otherOptions: [
    // {
    //   text: 'Facelift',
    //   to: '/procedures/facelift',
    // },
    {
      text: 'Nose Surgery',
      to: '/procedures/nose-surgery',
    },
    {
      text: 'Ear Correction',
      to: '/procedures/ear-correction',
    },
    {
      text: 'Earlobe Repair',
      to: '/procedures/ear-reconstruction',
    },
    {
      text: 'Eyelid Surgery',
      to: '/procedures/eyelid-surgery',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>Nose Surgery - Plastic Surgery Sydney</title>

      <meta
        name="description"
        content="Dr. Graham Sellars can improve your profile and overall attractiveness with nose surgery. Get the procedure you’ve always wanted."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
    </Hero>

    <Content>
      <h2>Nose Surgery - Rhinoplasty</h2>
      <h4>
        Rhinoplasty is a surgical procedure to improve the external appearance
        of the nose.
      </h4>
      <p>
        This procedure may be performed to restore the shape of your nose after
        an injury, or as a purely cosmetic procedure if you wish to change the
        way your nose has developed.
      </p>

      <p>
        Common reasons for Rhinoplasty include a crooked nose, bumps on the
        nose, a large nose, and asymmetry of the tip of the nose. These may be
        combined with having a blocked nose.
      </p>

      <p>
        At the same time as altering the external appearance of the nose, your
        surgeon may also treat any problems with breathing that you may have.
      </p>

      <p>
        A Rhinoplasty is a very common plastic surgery procedure with a high
        degree of patient satisfaction. It generally gives people a sense of
        increased self esteem and more confidence in their appearance.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Pre operative care</StyledTab>
        <StyledTab>Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>
          It is important to let Dr. Sellars know exactly what things you wish
          to change about your nose.
        </h4>

        <p>
          You will also need to advise them of any medical problems you have, if
          you have had any previous nasal surgery, what medications you are
          taking, and if you smoke.
        </p>

        <p>
          Common reasons for Rhinoplasty include a crooked nose, bumps on the
          nose, a large nose, and asymmetry of the tip of the nose. These may be
          combined with having a blocked nose.
        </p>

        <p>
          Pre-operative consultation on at least two separate occasions prior to
          the surgery is essential to ensure a realistic expectation of the
          surgery.
        </p>

        <p>
          This will involve Dr. Sellars showing you before and after photographs
          of other patients who have undergone the surgery and giving you
          reading material on the subject.
        </p>

        <p>
          If the surgery is to be performed due to a previous injury to the
          nose, or if you have problems breathing, then some of the surgical and
          anaesthetic fees and the hospital stay will be covered by Medicare and
          your Health Fund. Dr. Sellars and your fund can advise whether this
          applies to you.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Rhinoplasty is performed under general anaesthetic. Depending on what
          is involved with the surgery it may be performed as a day stay
          procedure, or it may involve an overnight hospital stay.
        </h4>
        <p>
          The incisions for rhinoplasty may be entirely hidden within the nose,
          or may involve a small scar across the skin between the nostrils.
          Dr.Sellars will advise you which type of incision is appropriate in
          your case.
        </p>

        <p>
          There are various techniques to reshape the nose. Sometimes the nasal
          bones will need to be broken and repositioned, and sometimes it may
          just be the nasal cartilage that needs to be manipulated to create a
          new shape.
        </p>

        <p>
          Occasionally cartilage grafts may have to be taken from the ear or
          ribs to give extra length, strength or form to a small or bulbous
          nasal tip.
        </p>

        <p>
          At the end of the surgery, a nasal splint or nasal packing may be
          required, to stabilise the septum (cartilage that runs up the middle
          of the nose).
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          When you wake up there may be packs inside the nose which will be
          removed a day or so after the surgery.
        </h4>
        <p>
          There will be a small plastic splint on the nose which is removed
          after a week, at the same time as any stitches are removed. Dr.
          Sellars may leave tape on the nose for another week after the splint
          is removed.
        </p>

        <p>
          There will be some bruising and swelling after the surgery for a week
          or two, and you may have black eyes.
        </p>

        <p>
          Sleeping with your head elevated on a couple of pillows for the first
          few nights following the surgery and using ice packs will help this to
          go as quickly as possible. It may take several months for the swelling
          to completely settle, and it is also common to have some numbness of
          the tip of the nose which can last for several months.
        </p>

        <p>
          After the surgery you should rest at home for the first week, avoiding
          strenuous activity and lifting.
        </p>

        <p>
          Sport will need to be restricted for a few weeks after the surgery,
          and any contact sport needs to be avoided for 2 months to avoid nasal
          injury.
        </p>

        <p>
          Dr Sellars will prescribe pain relief for you during your hospital
          stay and for the next few weeks should you require it.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific Nose Surgery (Rhinoplasty) risks:</h4>
        <ul>
          <li>Change of sensation or numbness that may last a few weeks</li>
          <li>Asymmetry of the nose</li>
          <li>Visible swelling that persists for weeks and months. It can take up to 12 months to see the final result</li>
          <li>Rarely, reduced sense of smell or loss of smell</li>
          <li>Reoperation – some patients may need a second procedure to further improve the outcome. This occurs in about one patient in ten</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After</h2>
      <div>
        {data.allFile.edges.map(
          (image) =>
            image.node.childImageSharp && (
              <Img resolutions={image.node.childImageSharp.resolutions} />
            )
        )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query NoseSurgeryQuery {
    allFile(filter: { relativeDirectory: { eq: "gallery/nose" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "sellars-nose.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "face-close-up.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
